import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

const RandoEn = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      rando: file(relativePath: { eq: "mascotteTourisme.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pont: file(relativePath: { eq: "pont-pyle.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura: file(relativePath: { eq: "jura.png" }) {
        childImageSharp {
          fixed(width: 138) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      convivialite: file(relativePath: { eq: "convivialite.jpg" }) {
        childImageSharp {
          fixed(width: 259) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      champetre: file(relativePath: { eq: "champetre.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vouglans: file(relativePath: { eq: "vouglans.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      famille: file(relativePath: { eq: "rando-famille.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      comte: file(relativePath: { eq: "comte.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clavelin: file(relativePath: { eq: "clavelin.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tresor: file(relativePath: { eq: "tresor.jpg" }) {
        childImageSharp {
          fixed(width: 223) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      panneau: file(relativePath: { eq: "panneau.png" }) {
        childImageSharp {
          fixed(width: 152) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cirque: file(relativePath: { eq: "cirque.png" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bgexcursion: allFile (
        filter: {relativePath: {regex: "/bg-excursion/"}}
        sort: { fields: [relativePath] }
      ) {
      nodes {
        childImageSharp {
          fluid (maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    }
  `)
  return (
    <Layout lang="en" location={location}>
      <SEO
        title="Tourist entertainer"
        description="Excursions, hikes, terroir, geocaching"
        lang="en"
      />
      <div className="submenu">
        <a href="#excursions">Excursions</a>
        <a href="#randonnee">Hiking</a>
        <a href="#terroir">Terroir</a>
        <a href="#caching">Geocaching</a>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2">
          <Img
            fluid={data.rando.childImageSharp.fluid}
            alt="Tourism in the Jura"
            style={{ maxWidth: 260, width: "100%" }}
          />
        </div>
        <div className="column">
          <h2>The job</h2>
          <p>
            The tourist guide is a modern nomad. From his passion for travelling
            he made his job.
          </p>
          <p>
            He conceives excursion programs, visits and touristic tours. He
            accompagnies tourists along their trip, highlighting the local
            culture, gastronomy and craft.
          </p>
        </div>
      </div>
      <div className="columns is-vcentered mt-6 is-variable is-7">
        <div className="column">
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
            frameborder="0"
            src="https://www.youtube.com/embed/dN-2BSeO-0w"
            title="Mes randonnées jurasiennes"
            style={{ width: "100%", height: 255 }}
          ></iframe>
        </div>
        <div className="column">
          <Img
            fixed={data.panneau.childImageSharp.fixed}
            alt="Panneau"
            style={{
              width: 152,
              float: "right",
              marginTop: -90,
              marginLeft: 20,
            }}
            className="is-hidden-mobile"
          />
          <h2 id="excursions">Excursions</h2>
          <p>
            Half day or full day excursions in order to discover our
            characterictic little comtoise towns , the local history and the
            legends, the “terroir” and the local craftmen with their typical
            skills (winegrowers, cheesemongers…).
          </p>
        </div>
      </div>
      <div className="is-hidden-tablet-only is-hidden-mobile">
        <div className="slider" style={{margin: '2rem auto 6rem'}}>
          {data.bgexcursion.nodes.map(bg => (
            <BackgroundImage fluid={bg.childImageSharp.fluid} />
          ))}
        </div>
      </div>
      <h2 className="mt-6" id="randonnee">
        Hiking
      </h2>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.pont.childImageSharp.fixed}
            alt="Pont de la pyle"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
        <div className="column">
          <Img
            fixed={data.cirque.childImageSharp.fixed}
            alt="Cirque de Beaume"
            style={{
              width: 260,
              float: "right",
              marginTop: -125,
              marginLeft: 20,
            }}
            className="is-hidden-mobile"
          />
          <p>I discovered hiking three years ago, with friends.</p>
          <p>
            I had no experience and I began as a simple member of the group.
          </p>
          <p>
            I started with short walks a few kilometers, with a low elevation
            and I increased them little by little.
          </p>
          <p>
            I thought I knew Jura, my native department quite well, but I
            discovered a new territory.
          </p>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.jura.childImageSharp.fixed}
            alt="Jura"
            style={{ maxWidth: 138, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Jura is well adapted to this activity, thanks to his geological and
            geographical structure.
          </p>
          <p>
            It provides a wide range of landcapes, from the Dole plain and
            Bresse to The Revermont, the premier plateau and the Haut Jura.
          </p>
        </div>
      </div>
      <div
        className="is-uppercase mb-6"
        style={{ fontFamily: "made", color: "#de6c21", fontSize: "1.4em" }}
      >
        I love hiking for several reasons:
      </div>
      <div className="columns is-vcentered">
        <div className="column">
          <h3>Exchanges</h3>
          <p>
            In a small group, speaking and discussing at one’s own pace in the
            middle of nature is very pleasant, allowing everybody to feel at
            case, according to one’s character.
          </p>
          <p>
            During the breaks, it is the moment to share and to taste local
            products.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.convivialite.childImageSharp.fixed}
            alt="Exchanges"
            style={{ maxWidth: 259, width: "100%" }}
          />
        </div>
      </div>
      <h3>Physical activity</h3>
      <p>
        Far from sports performances, I am going to privilege easy family
        excursions, accessible to a large number of people.
      </p>
      <h3>La Nature</h3>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.vouglans.childImageSharp.fixed}
            alt="Vouglans"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Whatever the season is, nature offers us changing landscapes, all of
            them wonderful, the green of spring, the yellow of corn fields in
            summer, the autumn brown and the white of winter, without forgetting
            the blue of our lakes, rivers, waterfalls and pounds, all of this of
            course for the great pleasure of photo lovers.
          </p>
          <p>
            I have tested several dozens of trips and I have selected the most
            interesting ones for you, concerning the landscape, the local
            heritage or the history of the place.
          </p>
          <p>So I invite you to follow me on the Jura roads</p>
        </div>
      </div>
      <div style={{ fontSize: 20, color: "#000" }}>You can choose</div>
      <div className="columns is-vcentered">
        <div className="column">
          <p>
            Family walks , easy or very easy , on short distances but with
            different touristic points of interest, or longer, during a whole
            day.
          </p>

          <p>
            These walks can be associated with the tasting of local products
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.famille.childImageSharp.fixed}
            alt="Randonnée familiale"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="terroir">Terroir</h2>
      <div className="columns is-vcentered">
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.comte.childImageSharp.fixed}
            alt="Comté"
            style={{ maxWidth: 260, width: "100%" }}
          />
        </div>
        <div className="column">
          <h3>Cheese</h3>
          <p>
            The most famous being Comté, that you can prefer young and soft or
            old and stronger, and also Morbier, Bleu de Gex and the well-known
            Mont d’or, so delicious hot with potatoes and Morteau sausages.
          </p>
          <p>
            All of them can be tasted and bought in the numerous “fruitières”of
            Jura.
          </p>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column">
          <h3>Wine</h3>
          <ul className="li-rando">
            <li>A vineyard with 7 PDO (protected denomination of origin)</li>
            <li>
              Geographical PDO (Arbois, Côte du Jura, l’Etoile, Château-Chalon)
            </li>
            <li>
              PDO products : Macvin a must for your aperitif in Jura, Cremant,
              to make the feast moments sparkle, Marc du Jura , appreciated
              after a good meal.
            </li>
            <li>
              Five grape varieties : chardonnay, savagnin,poulsard, pinot,
              trousseau.
            </li>
          </ul>
          <p>
            I shall help you to discover little producers who will share with
            you their passion for their work.
          </p>
          <p>
            And also salt sausages, Montbéliard, Morteau, honey with local
            beekeepers, and numerous brewers.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.clavelin.childImageSharp.fixed}
            alt="Clavelin"
            style={{ maxWidth: 250, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="caching">Géocaching</h2>
      <div className="columns is-vcentered mt-1">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.tresor.childImageSharp.fixed}
            alt="Géocaching"
            style={{ maxWidth: 223, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            A modern treasure hunt, intented for everybody. It is very funny to
            seach, to observe, to find these little tubes or boxes containing
            tiny objects, allowing to have a pleasant walk at the same time.
          </p>
          <p>Let us together for a treasure hunt!</p>
        </div>
      </div>
      <div style={{ fontFamily: "made", fontSize: 30 }}>
        <Link to="/en/agenda/">☞ My future benefits</Link>
      </div>
    </Layout>
  )
}

export default RandoEn
